import React, { useState } from 'react';

export default function Form({
    question, totalSubsidy, questions
}) {

    

    function SendMail(e){
        e.preventDefault();
        console.log(questions);

        fetch('sendEmail.php', {
            method: 'POST',
            body: JSON.stringify({test: 'true!'}),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
    }

    return (
        <form className="Answers Form" onSubmit={(e)=>{SendMail(e)}}>
            <input
                name="given-name"
                autoComplete="given-name"
                type="text"
                placeholder="Voornaam"
                required
            />
            <input
                name="email"
                autoComplete="email"
                type="email"
                placeholder="E-mailadres"
                required
            />
            <input
                name="tel"
                autoComplete="tel"
                type="text"
                placeholder="Telefoonnummer"
                required
            />
            <button className='WizardButton' type='submit'>Verstuur</button>
        </form>
    );
}