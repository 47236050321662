import React, { useState, useEffect } from 'react';
import Checklist from './Components/Questions/Checklist';
import Input from './Components/Questions/Input'
import Form from './Components/Questions/Form';
import { categories, questionTypes, initialQuestions, postcodes10000Regeling, postcodesWaardedalingsregeling } from './enums';

import logoBlack from '../images/subsidie-loket-zwart.jpg'



function CheckPostalCode(postalCode){
    return postcodes10000Regeling.includes(postalCode) || postcodesWaardedalingsregeling.includes(postalCode)
}

function UnsupportedQuestionType() {
    return <div>Unsupported question type</div>;
}

// Shown when first visiting.
function WizardEntry(props){
    return(
        <div className='WizardEntry'>
            <div className='Header'>
                <div>
                    <h1 className='HeaderInfoMain'>Doe nu onze gratis subsidiecheck!</h1>
                    <h3 className='HeaderInfoSecondary'>Binnen 3 dagen vrijblijvend subsidieadvies!</h3>
                </div>
                <div>
                    <button className='WizardButton' onClick={() => props.onComplete()}>Gratis subsidiecheck</button>
                </div>
            </div>
            <div className='Divider'></div>
            <div className='Benefits'>
                <div>
                    <div className='BenefitsCheckmark'></div>
                    <div className='Benefit'>Bespaar geld</div>
                </div>
                <div>
                    <div className='BenefitsCheckmark'></div>
                    <div className='Benefit'>Al binnen 3 werkdagen reactie</div>
                </div>
                <div>
                    <div className='BenefitsCheckmark'></div>
                    <div className='Benefit'>Geen verborgen kosten</div>
                </div>
            </div>
        </div>
    )
}

export default function Wizard() {
    const [wizardOpen, setWizardOpen] = useState(false);
    const [currentQuestionIndex, setQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState(initialQuestions);
    const [currentQuestion, setCurrentQuestion] = useState(questions[currentQuestionIndex]);
    const [totalSubsidy, setTotalSubsidy] = useState(0);

    useEffect(() => {
        setCurrentQuestion(questions[currentQuestionIndex]);
    }, [currentQuestionIndex, questions]);

    function prevQuestion() {
        // Set current question fields to empty.
        currentQuestion.fields.map((field) => {
            if(currentQuestion.type === questionTypes.checklist) delete field.selected;
            if(currentQuestion.type === questionTypes.input) delete field.answer;
        });

        let prevIndex = Math.max(currentQuestionIndex - 1, 0);
        let newIndex = prevIndex;
        const prevQuestion = questions[prevIndex];

        // Recursively check each previous question. Keep decrementing the prevIndex if that question is not answered yet,
        // stop until one is reached that did have an answer; or the question-index === 0.
        if(prevIndex !== 0){

            // Recursive method
            const GetPrevIndex = (pIndex) => {
                if(pIndex === 0) return pIndex;
                const q = questions[pIndex];

                for (let index = 0; index < q.fields.length; index++) {
                    const field = q.fields[index];
                    if (field.selected || field.answer) {
                        return pIndex;
                    }
                }
                pIndex--;

                return GetPrevIndex(pIndex);
            };

            newIndex = GetPrevIndex(prevIndex);
        }

        // if previous question had the category 'subsidy' and a +1 option was selected, decrease the counter.
        if(prevQuestion.category === categories.subsidies){
            let hadSubsidy = false;
            for (let index = 0; index < prevQuestion.fields.length; index++) {
                const field = prevQuestion.fields[index];
                if (field.selected || field.answer) {
                    if(field.increaseSubsidie){
                        hadSubsidy = true;
                    }
                    if(field.checkPostalCode){
                        const postalCode = Number(field.answer.replace(/\D/g,''));
                        if(CheckPostalCode(postalCode)) hadSubsidy = true;
                    }
                    break;
                }
            }
            if(hadSubsidy) setTotalSubsidy(totalSubsidy-1);
        }

        setQuestionIndex(newIndex);
        setCurrentQuestion(questions[newIndex]); // Update current question based on the new index
    }

    function nextQuestion() {

        // Check if all fields are answered (differs per type).
        let hasAnswered;
        switch (currentQuestion.type) {
            case questionTypes.checklist:
                hasAnswered = false;
                for (let index = 0; index < currentQuestion.fields.length; index++) {
                    const field = currentQuestion.fields[index];
                    if (field.selected) {
                        hasAnswered = true;
                        break;
                    }
                }   
                break;
            case questionTypes.input:
                currentQuestion.fields.forEach(field => {
                    hasAnswered = true;
                    if(!field.answer) hasAnswered = false;
                });
                break;
            default:
                break;
        }
        if (!hasAnswered) return;

        // Check each field for certain options.
        for (let index = 0; index < currentQuestion.fields.length; index++) {
            const field = currentQuestion.fields[index];
            if (field.selected || field.answer) {

                // Add +1 to totalSubsidy if selected
                if(field.increaseSubsidie){
                    setTotalSubsidy(totalSubsidy+1);
                }

                // Add +1 to totalSubsidy if given postal-code is in list of postal-codes that can get a subsidy.
                if(field.checkPostalCode){
                    const postalCode = Number(field.answer.replace(/\D/g,''));
                    if(CheckPostalCode(postalCode)) setTotalSubsidy(totalSubsidy+1)
                }

                // Go directly to question at index 'skipTo'
                if(field.skipTo){
                    setQuestionIndex(field.skipTo);
                    return;
                }
            }
        }
        
        // Clamp nextIndex between 0 and 'amount of questions'.
        const nextIndex = Math.min(Math.max(currentQuestionIndex+1, 0), questions.length-1);
        setQuestionIndex(nextIndex)
    }

    function updateQuestion(updatedField, fieldIndex) {
        const updatedQuestions = questions.map((question, qIndex) => {
            if (qIndex === currentQuestionIndex) {
                const updatedFields = question.fields.map((field, fIndex) => {
                    if (fIndex === fieldIndex) {
                        return { ...field, ...updatedField };
                    }
                    return field;
                });
                return { ...question, fields: updatedFields };
            }
            return question;
        });
    
        setQuestions(updatedQuestions);
        setCurrentQuestion(updatedQuestions[currentQuestionIndex]);
    }

    function RenderQuestionFields(){
        let QuestionFieldComponent;
        switch (currentQuestion.type) {
            case questionTypes.checklist:
                QuestionFieldComponent = Checklist;
                break;
            case questionTypes.input:
                QuestionFieldComponent = Input;
                break;
            case questionTypes.form:
                QuestionFieldComponent = Form;
                break;
            default:
                QuestionFieldComponent = UnsupportedQuestionType;
                break;
        }

        return (
            <QuestionFieldComponent question={currentQuestion} currentQuestionIndex={currentQuestionIndex} nextQuestion={nextQuestion} prevQuestion={prevQuestion} updateQuestion={updateQuestion} totalSubsidy={totalSubsidy} questions={questions}/>
        )
    }

    if (!wizardOpen) {
        return (
            <div className='Wizard'>
                <WizardEntry onComplete={() => setWizardOpen(true)} />
            </div>
        );
    }

    let FollowUpConclusion;
    let hasAnswered;
    let showSubsidy;
    for (let index = 0; index < currentQuestion.fields.length; index++) {
        const field = currentQuestion.fields[index];
        if (field.selected || field.answer) {
            hasAnswered = true;
            if(field.FollowUpConclusion) FollowUpConclusion = field.FollowUpConclusion;
            if(field.showSubsidy) showSubsidy = true;
            break;
        }
    }

    return (
        <div className='Wizard Open'>
            <div className='CategoryPanel'>
                {Object.entries(categories).map((category, index) => (
                    <div key={index} className={`Category ${category[1] === currentQuestion.category ? 'Active' : category[1] < currentQuestion.category ? 'Completed' : ''}`}>
                        <div className='CategoryNumber'>{category[1]}</div>
                        <div className='CategoryInfo'>
                            <div className='Step'>Stap {category[1]}</div>
                            <div className='Name'>{category[0].charAt(0).toUpperCase() + category[0].slice(1)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='QuestionPanel'>
                <div className="Question">{currentQuestion.question}</div>
                <RenderQuestionFields />
                <div className='FollowUpConclusion'>
                    {FollowUpConclusion
                        ? FollowUpConclusion.Conclusion
                        : ''
                    }
                    {showSubsidy
                        ? totalSubsidy > 0 
                            ?   <div>U heeft mogelijk recht op <span style={{fontWeight: 'bold'}}>{totalSubsidy}</span> subsidie(s). Het Subsidie Loket adviseert, begeleidt en ontzorgt u graag bij het regelen van deze subsidies.</div>
                            :   <div>U heeft geen directe subsidiekans zichtbaar, maar laten we samen de mogelijkheden verkennen. Contacteer ons.</div>
                        : ''
                    }
                </div>
                <div className='Pagination'>
                    {
                        currentQuestionIndex < questions.length-1 && (!FollowUpConclusion || !FollowUpConclusion.HideNextStepButton) && hasAnswered
                        ?   <button className='WizardButton' onClick={() => nextQuestion()}>Volgende stap</button>
                        :   ''
                    }
                    {
                        currentQuestionIndex > 0
                        ?   <button onClick={() => prevQuestion()}>Vorige stap</button>
                        :   ''
                    }
                    
                </div>
            </div>
            
        </div>
    );
}