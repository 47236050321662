import './styles.css';
import './styles-small.css';
import Wizard from './Wizard';

import logo from './images/subsidie-loket-horizontaal.png'


// Entry-point of this tool.
export default function SubsidieCheck() {
  return (
    <div className="App">
      <Wizard />
      <img className='SubsidieLoketLogo' src={logo}/>
    </div>
  );
}