import React, { useEffect, useRef } from 'react';

export default function Input({
    question,
    updateQuestion,
}) {
    // Use useRef to keep track of the input elements
    const inputRefs = useRef([]);

    function handleUpdate(fieldIndex, newValue) {
        const updatedField = { answer: newValue };
        updateQuestion(updatedField, fieldIndex);
    }

    useEffect(() => {
        // Automatically focus the first input field as an example
        if (inputRefs.current.length > 0) {
            inputRefs.current[0].focus();
        }
    }, []);

    // Update the rendering of fields to assign refs
    let Fields = question.fields.map((field, index) => (
        <div key={index}>
            <input
                ref={el => inputRefs.current[index] = el} // Assign the ref to the corresponding input element
                name="postalCode"
                autoComplete="postalCode"
                type="text"
                pattern="[0-9\s]{13,19}"
                maxLength={6}
                onChange={(e) => handleUpdate(index, e.target.value)}
                placeholder={field.text}
                value={field.answer || ''} // Ensure controlled component with a default value
            />
        </div>
    ));

    return (
        <div className="Answers">{Fields}</div>
    );
}
